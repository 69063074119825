import { useState } from "react";
import copyIcon from "assets/img/icons/copy.svg";
import checkLgIcon from "assets/img/icons/check-lg.svg";
function CopyBtn({ text }: any) {
  const [isCopied, setIsCopied] = useState(false);

  const handleButtonClick = () => {
    navigator.clipboard.writeText(text);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <button
      type="button"
      onClick={handleButtonClick}
      className="copy-btn"
      title="Copy"
    >
      <img src={isCopied ? checkLgIcon : copyIcon} alt="copy" />
    </button>
  );
}
export default CopyBtn;
