import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  verifyAuth: {}
};

export const authSlice = createSlice({
  name: "authuser",
  initialState,
  reducers: {
    setVerifyAuth: (state, action) => ({
      ...state,
      verifyAuth: action.payload,
    }),
   
  },
});

export const { setVerifyAuth } =
  authSlice.actions;
export default authSlice.reducer;
