import { useEffect, useState } from "react";

import CopyBtn from "common/components/CopyBtn";
import chevronDownIcon from "assets/img/icons/chevron-down.svg";

const Iframe = () => {
  const baseIframeUrl = "http://localhost:3000/iframe-listener";
  const [iframeUrl, setIframeUrl] = useState("");
  const [formState, setFormState] = useState<any>({});
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleInput = ({ target: { name, value } }: any) => {
    setFormState((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (formState.billingId && formState.privateKey) {
      const concatString = formState.billingId + ":" + formState.privateKey;
      const encodedString = btoa(concatString);
      setFormState({ ...formState, basicToken: encodedString });
    }
    //eslint-disable-next-line
  }, [formState.billingId, formState.privateKey]);

  const handleIframe = () => {
    formState?.authToken &&
      setIframeUrl(`${baseIframeUrl}?authToken=${formState?.authToken}`);
  };

  // useEffect(() => {
  //   dispatch(getUserList());
  // }, []);

  return (
    <section className="p-3 iframe-section">
      <div className="card iframe-form-cover">
        <button
          type="button"
          className="row justify-content-between cursor-pointer align-items-center"
          onClick={() => setIsFormOpen((p) => !p)}
        >
          <h6 className="mb-0">Form Details</h6>
          <img src={chevronDownIcon} alt="open" width={16} />
        </button>
        {isFormOpen && (
          <div className="row pt-3">
            <div className="col-md-6">
              <label className="mb-3">
                <span className="label">Billing GUID:</span>
                <input
                  type="text"
                  className="form-control"
                  name="billingId"
                  value={formState.billingId}
                  onChange={handleInput}
                />
              </label>
              <label className="mb-3">
                <span className="label">Private Key:</span>
                <input
                  type="text"
                  className="form-control"
                  name="privateKey"
                  value={formState.privateKey}
                  onChange={handleInput}
                />
              </label>
              <label className="mb-3">
                <span className="label">Basic Auth Token:</span>
                <input
                  type="text"
                  className="form-control"
                  name="basicToken"
                  value={formState.basicToken}
                  onChange={handleInput}
                  disabled
                />
                <CopyBtn text={formState.basicToken} />
              </label>
            </div>
            <div className="col-md-6">
              <label>
                <span className="label">User:</span>
                <select
                  className="form-select"
                  name="user"
                  value={formState.user}
                  onChange={handleInput}
                >
                  <option value=""></option>
                  <option value="new-user" disabled={!formState?.basicToken}>
                    Create New User
                  </option>
                  <option value="example@test.com">example@test.com</option>
                  <option value="example2@test.com">example2@test.com</option>
                </select>
              </label>
              {formState.user === "new-user" && (
                <div className="card bg-light mt-3">
                  <label className="mb-3">
                    <span className="label">First Name:</span>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={formState.firstName}
                      onChange={handleInput}
                    />
                  </label>
                  <label className="mb-3">
                    <span className="label">Middle Name:</span>
                    <input
                      type="text"
                      className="form-control"
                      name="middleName"
                      value={formState.middleName}
                      onChange={handleInput}
                    />
                  </label>
                  <label className="mb-3">
                    <span className="label">Last Name:</span>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={formState.lastName}
                      onChange={handleInput}
                    />
                  </label>
                  <label className="">
                    <span className="label">Email:</span>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formState.email}
                      onChange={handleInput}
                    />
                  </label>
                  <div className="mt-3 mx-auto">
                    <button type="button" className="btn btn-success">
                      Create User
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-3 text-center mx-auto">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleIframe()}
                disabled={!formState?.authToken}
              >
                Load Iframe
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-3">
        <div className="col">
          <label>
            <span className="label col-auto">User ID:</span>
            <input
              type="text"
              className="form-control"
              name="userId"
              value={formState.userId}
              disabled
            />
            <CopyBtn text={formState.userId} />
          </label>
        </div>
        <div className="col">
          <label>
            <span className="label">Email:</span>
            <input
              type="text"
              className="form-control"
              name="userId"
              value={formState.email}
              disabled
            />
            <CopyBtn text={formState.email} />
          </label>
        </div>
        <div className="col">
          <label>
            <span className="label">Token:</span>
            <input
              type="text"
              className="form-control"
              name="token"
              value={formState.token}
              disabled
            />
            <CopyBtn text={formState.token} />
          </label>
        </div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-primary"
            disabled={!formState?.authToken}
          >
            Download Packet
          </button>
        </div>
      </div>
      <div className="card mt-3">
        {iframeUrl ? (
          <iframe
            title={`third_party_integration${iframeUrl}`}
            src={iframeUrl}
            style={{ width: "100%", height: "100vh", border: "none" }}
          ></iframe>
        ) : (
          <h5 className="text-center">Load Iframe</h5>
        )}
      </div>
    </section>
  );
};

export default Iframe;
